<template>
  <div>
    <div class="question-type-nav">
      <button
        :class="{ 'active-table': isViewingSubjective == 0 }"
        class="mcq btn "
        @click="isViewingSubjective = 0"
      >
        Multiple Choice Question
      </button>
      <button
        :class="{ 'active-table': isViewingSubjective == 2 }"
        class="subjective-question btn "
        @click="isViewingSubjective = 2"
      >
        Subjective Question
      </button>
      <button
        :class="{ 'active-table': isViewingSubjective == 1 }"
        class="subjective-question btn "
        @click="isViewingSubjective = 1"
      >
        One Word Questions
      </button>
    </div>
    <div class="table-container">
      <table class="table">
        <DetailedAnalysisRow
          col0="Sno"
          col1="Question"
          col2="Answer"
          col3="Difficulty"
          col4="Your Answer"
          col5="Your Marks"
          col6="Explanation"
          row-back-ground-color="#46A049"
          font-weight="bolder"
          :render="true"
          :render-answer="isViewingSubjective == 2 ? false : true"
        ></DetailedAnalysisRow>

        <DetailedAnalysisRow
          v-for="(row, index) in tableData"
          :key="index"
          :col0="index + 1"
          :col1="row.question"
          :col2="row.answer"
          :col3="row.difficulty"
          :col4="row.userAnswer != -1 ? row.userAnswer : ''"
          :col5="row.marks"
          :col6="row.explanation"
          :render-answer="true"
          row-back-ground-color="#ffffff"
          font-weight="lighter"
          :render="isViewingSubjective == 0"
        >
        </DetailedAnalysisRow>

        <DetailedAnalysisRow
          v-for="(row, index) in subjectiveQuestion"
          :key="index"
          :col0="index + 1"
          :col1="row.question"
          :col2="row.answer"
          :col3="row.difficulty"
          :col4="row.response != -1 ? row.response : ''"
          :col5="row.marks"
          :col6="row.explanation"
          :render-answer="isViewingSubjective == 2 ? false : true"
          row-back-ground-color="#ffffff"
          font-weight="lighter"
          :render="isViewingSubjective == 2"
          :is-admin="user.data.admin"
          type="sub"
          :id="row.id"
          :total="row.total"
          @marksUpdated="marksUpdated"
        ></DetailedAnalysisRow>
        <DetailedAnalysisRow
          v-for="(row, index) in oneWordQuestions"
          :key="index"
          :col0="index + 1"
          :col1="row.question"
          :col2="row.correct"
          :col3="row.difficulty"
          :col4="row.response != -1 ? row.response : ''"
          :col5="row.marks"
          :render-answer="true"
          :col6="row.explanation"
          row-back-ground-color="#ffffff"
          font-weight="lighter"
          :render="isViewingSubjective == 1"
          :is-admin="user.data.admin"
          type="one"
          :id="row.id"
          :total="row.total"
          @marksUpdated="marksUpdated"
        ></DetailedAnalysisRow>
      </table>
    </div>
  </div>
</template>

<script>
import DetailedAnalysisRow from "@/components/AnalysisComponents/DetailedAnalysisRow";
import store from "@/store";
import { mapGetters } from "vuex";
import { questions, testTakers } from "@/Utils/global";
import db from "@/firebase";

export default {
  name: "DetailedAnalysis",
  components: { DetailedAnalysisRow },
  created() {
    console.log(this.$route.params);
    this.test_id = this.$route.query.test_id;
    this.page_id = this.$route.query.page_id;
    this.user_id = store.state.user.data.uid;

    console.log(this.user);
    if (this.user.data.admin) {
      this.user_id = this.$route.query.user_test_id;
    }

    this.fetchData();
  },
  data() {
    return {
      tableData: [],
      responses: [],
      correctAnswer: [],
      test_id: 0,
      page_id: 0,
      user_id: 0,
      subjectiveQuestion: [],
      isViewingSubjective: 0, //0 for mcq,1 for one word, 2 for subjective;
      subjectiveEvaluated: false,
      oneWordQuestions: [],
      questionId: {}
    };
  },
  methods: {
    async fetchData() {
      let d = await testTakers(this.page_id, this.test_id, this.user_id);

      let data = d.data();
      console.log(data);

      this.subjectiveEvaluated = data["reviewed"];

      let correctAnswer = await questions(this.page_id, this.test_id);

      this.correctAnswer = correctAnswer.docs.map(doc => doc.data());

      this.correctAnswer = this.correctAnswer.sort(this.sort_questions);

      this.correctAnswer.forEach((data, index) => {
        this.questionId[data.id] = index;
      });

      console.log(this.correctAnswer);

      this.responses = data["responses"];

      this.fillTableData();
    },

    sort_questions(a, b) {
      if (parseInt(a.id) < parseInt(b.id)) return -1;
      else return 1;
    },

    fillTableData() {
      this.responses.forEach((response, index) => {
        if (this.correctAnswer[index].subType == 0) {
          let oneWord = {
            response: response["response"],
            marks: response["marks"],
            id: this.correctAnswer[index].id,
            total: this.correctAnswer[index].marks
          };
          console.log(response);

          this.oneWordQuestions.push({
            ...this.correctAnswer[index],
            ...oneWord
          });
        } else if (!("correct" in this.correctAnswer[index])) {
          let userSubjectiveResponse = {
            response: response["response"],
            marks: response["marks"],
            id: this.correctAnswer[index].id,

            total: this.correctAnswer[index].marks
          };

          this.subjectiveQuestion.push({
            ...this.correctAnswer[index],
            ...userSubjectiveResponse
          });
        } else {
          let singleRowData = {
            question: this.correctAnswer[index]["question"],
            answer: this.correctAnswer[index]["correct"],
            userAnswer:
              this.responses[index]["response"] === -1
                ? "Not Attempted"
                : `${this.responses[index]["response"]}`,
            explanation: this.correctAnswer[index]["explanation"],
            marks: this.responses[index]["marks"],
            difficulty: this.correctAnswer[index]["difficulty"]
          };

          this.tableData.push(singleRowData);
        }
      });
    },
    marksUpdated(data) {
      if (data.type == "one") {
        this.oneWordQuestions.forEach(oneWord => {
          if (oneWord.id === data.id) {
            oneWord.marks = data.marks;
          }
        });
      } else {
        this.subjectiveQuestion.forEach(subjective => {
          if (subjective.id === data.id) {
            subjective.marks = data.marks;
          }
        });
      }

      console.log(data);

      this.responses[this.questionId[data.id]].marks = data.marks;
      db.collection("tests" + this.page_id)
        .doc(this.test_id)
        .collection("test_takers")
        .doc(this.user_id)
        .set(
          {
            responses: this.responses
          },
          {
            merge: true
          }
        );
    }
  },
  computed: {
    ...mapGetters({
      user: "user"
    })
  }
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Mukta:wght@300&display=swap");

table,
th,
td {
  border: 1px solid black;
}

.table-title {
  color: red;
  align-items: center;
  text-align: center;
  font-family: "Mukta", sans-serif;
}

.table-container {
  margin: 0 50px 50px 50px;
}

table {
  width: 100%;
  overflow-x: hidden;
  border-collapse: collapse;
}

.table-body {
  overflow: auto;
}

.active-table {
  color: white;
  background-color: #337ab7 !important;
}

.question-type-nav {
  /*background-color: chocolate;*/
  padding: 10px;
  width: 80%;
  margin: 0 auto;
}

.mcq {
  width: 30%;
  background-color: white;
  border-top: 1px solid black;
  border-left: 1px solid black;
  border-bottom: 1px solid black;
  border-right: 1px solid black;
  border-radius: 0;
}

.subjective-question {
  width: 30%;
  background-color: white;
  border-radius: 0;
  border-top: 1px solid black;
  border-right: 1px solid black;
  border-bottom: 1px solid black;
}

.btn.focus,
.btn:focus {
  box-shadow: none;
}

@media all and (max-width: 700px) {
  .table-container {
    width: 85vw;

    overflow-x: auto;
    white-space: nowrap;
  }
}

@media all and (max-width: 700px) {
  .question-type-nav {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }

  .table-container {
    margin-left: 10px;
  }

  .btn {
    /*word-wrap: break-word;*/
    white-space: normal;
    height: 100px;
  }
}
</style>
